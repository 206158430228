const ESCAPE = 27;
const fn = f => typeof f === 'function';

const defaults = {
  hover: false,
  click: false,
  parent: null,
  buttons: [],
  beforeOpenHook: null,
  beforeCloseHook: null,
  afterOpenHook: null,
  afterCloseHook: null,
  toggleBodyNavClass: false,
  toggleBodyDropdownClass: false,
  closeOnBodyClick: true,
  keepOpenIfInputFocused: true,
  bodyDropdownClass: 'is-dropdown-active',
  bodyNavClass: 'is-nav-active',
  parentActiveClass: 'WUG-Dropdown-Parent--Active',
  buttonActiveClass: 'WUG-Dropdown-Trigger--Active',
  dropdownActiveClass: 'WUG-Dropdown--Active'
};

/**
 *
 */
export class Dropdown {
  /**
   * @param {HTMLElement} element - the dropdown element
   * @param {Object} [options=defaults] - options object
   * @param {Boolean} [options.hover=false] - toggle dropdown on hover
   * @param {Boolean} [options.click=false] - toggle dropdown on click
   * @param {HTMLElement} [option.parent] - optional parent element to apply classes on - defaults to real dropdown parent if not given
   * @param {HTMLElement|HTMLElement[]} [option.buttons] - all buttons that can toggle the dropdown
   * @param {Function} [options.beforeOpenHook] - function to run prior open dropdown
   * @param {Function} [options.beforeCloseHook] - function to run prior close dropdown
   * @param {Boolean} [options.toggleBodyNavClass=false] - whether to toggle class on body when toggling the navigation
   * @param {Boolean} [options.toggleBodyDropdownClass=false] - whether to toggle class on body when toggling the dropdown
   * @param {Boolean} [options.closeOnBodyClick=true] - whether to close the dropdown when body is clicked
   * @param {String} [options.keepOpenIfInputFocused] - what it says
   * @param {String} [options.bodyNavClass] - classname toggled on the body
   * @param {String} [options.bodyDropdownClass] - classname toggled on the body
   * @param {String} [options.parentActiveClass] - classname toggled on the parent element
   * @param {String} [options.buttonActiveClass] - classname toggled on the button(s)
   * @param {String} [options.dropdownActiveClass] - classname toggled on the dropdown itself
   */
  constructor(element, options) {
    if (!element) {
      return;
    }

    this.element = element;
    this.options = Object.assign({}, defaults, options);

    this.parent = this.options.parent || element.parentElement;
    this.buttons = Array.from(this.options.buttons);

    if (!this.buttons.length) {
      this.buttons = [this.parent.firstElementChild];
    }

    this.closed = true;

    this.beforeOpenHook = this.options.beforeOpenHook;
    this.beforeCloseHook = this.options.beforeCloseHook;
    this.afterOpenHook = this.options.afterOpenHook;
    this.afterCloseHook = this.options.afterOpenHook;

    this.boundOpen = this.open.bind(this);
    this.boundClose = this.close.bind(this);
    this.boundToggle = this.toggle.bind(this);
    this.boundCloseOnEscape = this.closeOnEscape.bind(this);
    this.boundCloseOnBodyClick = this.closeOnBodyClick.bind(this);

    this.init();
  }

  /**
   * @param {Function} beforeOpenFn
   */
  /*
  setBeforeOpenHook(beforeOpenFn) {
    if (!fn(beforeOpenFn)) {
      throw new Error('beforeOpenHook function is required');
    }

    this.beforeOpenHook = beforeOpenFn;
  }
  */

  /**
   * @param {Function} beforeCloseFn
   */
  /*
  setBeforeCloseHook(beforeCloseFn) {
    if (!fn(beforeCloseFn)) {
      throw new Error('beforeCloseHook function is required');
    }

    this.beforeCloseHook = beforeCloseFn;
  }
  */

  /**
   *
   */
  open() {
    if (!this.closed) return;

    if (fn(this.beforeOpenHook)) {
      this.beforeOpenHook(this);
    }

    this.parent.classList.add(this.options.parentActiveClass);

    this.buttons.forEach((button) => {
      button.classList.add(this.options.buttonActiveClass);
      button.setAttribute('aria-expanded', true);
    });

    this.element.classList.add(this.options.dropdownActiveClass);

    if (this.options.toggleBodyNavClass) {
      document.body.classList.add(this.options.bodyNavClass);
    }
    if (this.options.toggleBodyDropdownClass) {
      document.body.classList.add(this.options.bodyDropdownClass);
    }

    document.addEventListener('keydown', this.boundCloseOnEscape, false);

    if (this.options.closeOnBodyClick) {
      document.addEventListener('click', this.boundCloseOnBodyClick);
    }

    this.closed = false;

    if (fn(this.afterOpenHook)) {
      this.afterOpenHook();
    }
  }

  /**
   *
   */
  close() {
    if (this.closed) return;

    if (this.options.keepOpenIfInputFocused) {
      if (document.querySelector('.WUG-Input') === document.activeElement) return;
    }

    if (fn(this.beforeCloseHook)) {
      this.beforeCloseHook(this);
    }

    this.parent.classList.remove(this.options.parentActiveClass);

    this.buttons.forEach((button) => {
      button.classList.remove(this.options.buttonActiveClass);
      button.setAttribute('aria-expanded', false);
    });

    this.element.classList.remove(this.options.dropdownActiveClass);

    if (this.options.toggleBodyNavClass) {
      document.body.classList.remove(this.options.bodyNavClass);
    }

    const isAnyMobileMenuOpen = document.querySelectorAll('.WUG-Right > .WUG-Menu-Mobile > .WUG-Dropdown-Parent--Active, .WUG-Right.' + this.options.parentActiveClass).length;
    if (this.options.toggleBodyDropdownClass && !isAnyMobileMenuOpen) {
      document.body.classList.remove(this.options.bodyDropdownClass);
    }

    document.removeEventListener('keydown', this.boundCloseOnEscape, false);

    if (this.options.closeOnBodyClick) {
      document.removeEventListener('click', this.boundCloseOnBodyClick);
    }

    this.closed = true;

    if (fn(this.afterCloseHook)) {
      this.afterCloseHook();
    }
  }

  /**
   * @param {Event} event
   */
  closeOnEscape(event) {
    if (event.keyCode === ESCAPE) {
      this.close();
    }
  }

  /**
   * @param {Event} event
   */
  closeOnBodyClick(event) {
    const { target } = event;

    if (target === this.parent) {
      return;
    }

    if (this.element.contains(target)) {
      return;
    }

    if (this.buttons.includes(target)) {
      return;
    }

    if (this.buttons.some(button => button.contains(target))) {
      return;
    }

    this.close();
  }

  /**
   *
   */
  toggle() {
    this.closed ? this.open() : this.close();
  }

  /**
   * @param {String} action - ENUM { "click", "hover", "body-click" }
   */
  turnOn(action) {
    if (action === 'click') {
      this.buttons.forEach(button => button.addEventListener('click', this.boundToggle, false));
    }

    if (action === 'hover') {
      this.buttons.forEach(button => button.addEventListener('focus', this.boundOpen, false ));

      this.parent.addEventListener('mouseenter', this.boundOpen, false);
      this.parent.addEventListener('mouseleave', this.boundClose, false);
    }

    if (action === 'body-click') {
      this.options.closeOnBodyClick = true;
    }
  }

  /**
   * @param {String} action - ENUM { "click", "hover", "body-click" }
   */
  turnOff(action) {
    if (action === 'click') {
      this.buttons.forEach(button => button.removeEventListener('click', this.boundToggle, false));
    }

    if (action === 'hover') {
      this.buttons.forEach(button => button.removeEventListener('focus', this.boundOpen, false ));

      this.parent.removeEventListener('mouseenter', this.boundOpen, false);
      this.parent.removeEventListener('mouseleave', this.boundClose, false);
    }

    if (action === 'body-click') {
      this.options.closeOnBodyClick = false;
    }
  }

  /**
   *
   */
  init() {
    if (this.options.click) {
      this.turnOn('click');
    }

    if (this.options.hover) {
      this.turnOn('hover');
    }
  }
}
