/**
 * Skip to content setup
 */
export function setupSkipToContent() {
  const nav = document.querySelector('#js-wug-nav');
  const link = nav.querySelector('#js-wug-nav-skip-to-content-link');
  const skipper = document.createElement('div');

  if (!nav || !link) {
    return;
  }

  skipper.tabIndex = 0;
  skipper.id = 'skip-to-content';
  skipper.classList.add('WUG-Skipper');

  link.addEventListener('click', (event) => {
    nav.after(skipper);
    link.blur();
    skipper.focus();
    skipper.remove();
    setTimeout(skipper.remove);
    event.preventDefault();
  }, false);
}
