import 'classlist-polyfill';
// import 'custom-event-polyfill';
import closest from 'element-closest';
import 'core-js/features/promise/index';
import 'core-js/features/array/from';
import 'core-js/features/array/includes';
import 'core-js/features/object/assign';
import 'core-js/features/string/includes';
import 'core-js/features/string/starts-with';
import 'core-js/features/set';
import 'core-js/features/map';
// import 'whatwg-fetch';
import { init } from './init.mjs';

closest(window);
init();
