// Function populating language menus reading data from DOM

/**
 * @return {Map} data - cultute locales data links
 */
function getCultureDataFromDOM() {
  const map = new Map();
  const nav = document.querySelector('#js-culture-links');

  if (!nav) return map;

  const links = Array.from(nav.querySelectorAll('a'));

  links.forEach(link => map.set(link.dataset.culture, {
    url: link.href,
    text: link.innerText.trim(),
    active: link.classList.contains('is-active'),
    culture: link.dataset.culture
  }));

  return map;
}

/**
 * @return {void}
 */
export function populateNavLocaleLinks() {
  const locales = getCultureDataFromDOM();
  const localeMenuMobile = document.querySelector('#js-wug-nav-locales-mobile');
  const localeMenuDesktop = document.querySelector('#js-wug-nav-locales-desktop');

  if (!locales.size) return;
  if (!localeMenuMobile) return;
  if (!localeMenuDesktop) return;

  const mobileLinks = Array.from(localeMenuMobile.querySelectorAll('.WUG-Locale-Link'));
  const desktopLinks = Array.from(localeMenuDesktop.querySelectorAll('.WUG-Locale-Link'));

  [...mobileLinks, ...desktopLinks].forEach((link) => {
    const { culture } = link.dataset;

    if (!locales.get(culture)) return;

    const { url } = locales.get(culture);
    link.href = url;
  });
}
