import { Dropdown } from './dropdown.mjs';
import { interactive } from './interactive.mjs';
import { setupSkipToContent } from './skip-to-content.mjs';
import { populateNavLocaleLinks } from './culture-locales.mjs';

const MOBILE_BREAKPOINT = 1270;

const isBelowBreakpoint = () => Math.max(document.documentElement.clientWidth, window.innerWidth || 0) <= MOBILE_BREAKPOINT;
const isAboveBreakpoint = () => !isBelowBreakpoint();

const preventDefault = event => event.preventDefault();
const onTapEnterKeydown = event => event.keyCode === 13 && event.target.click();

/**
 * Difference between two sets
 * @param {Set} setA
 * @param {Set} setB
 * @return {Set} result - result set
 */
function difference(setA, setB) {
  const result = new Set(setA);
  setB.forEach(el => result.delete(el));
  return result;
}

/**
 * init function
 * @return {Promise<void>}
 */
export function init() {
  if (window.WUG_NAV_INITIALIZED) {
    return Promise.resolve();
  }

  window.WUG_NAV_INITIALIZED = true;

  return interactive().then(() => {
    const nav = document.querySelector('#js-wug-nav');
    const searchDesktop = nav.querySelector('#js-wug-nav-search-desktop');
    const searchMobile = nav.querySelector('#js-wug-nav-search-mobile');

    /** close dropdowns when any of these elements is focused */
    const focusLinks = Array.from(difference(
      new Set(nav.querySelectorAll('a, button')),
      new Set(nav.querySelectorAll('.WUG-Dropdown a, .WUG-Dropdown button'))
    ));

    /** mobile dropdown tap/click area */
    const taps = Array.from(nav.querySelectorAll('.WUG-Dropdown-Link-Mobile-Tap-Area'));

    /** locales */
    const localesMobileMenu = nav.querySelector('#js-wug-nav-locales-mobile');
    const localesDesktopMenu = nav.querySelector('#js-wug-nav-locales-desktop');

    /** drawer */
    const drawerMenu = nav.querySelector('#js-wug-nav-drawer');
    const drawerButton = nav.querySelector('#js-wug-nav-button');

    /** in drawer menus */
    const inDrawerMenus = Array.from(drawerMenu.querySelectorAll('.WUG-Dropdown, .WUG-Sub-Dropdown'));


    /**
     * Setup locales dropdown menus
     */
    const localesMobileDropdown = new Dropdown(localesMobileMenu, {
      click: true,
      toggleBodyDropdownClass: true
    });
    const localesDesktopDropdown = new Dropdown(localesDesktopMenu, {
      hover: true,
      toggleBodyDropdownClass: false
    });

    /**
     * Setup search dropdown on mobile
     */
    const searchMobileDropdown = new Dropdown(searchMobile, {
      click: true,
      toggleBodyDropdownClass: true
    });

    /**
     * Setup main drawer menu
     */
    const drawerDropdown = new Dropdown(drawerMenu, {
      click: true,
      toggleBodyDropdownClass: true,
      toggleBodyNavClass: true,
      buttons: [drawerButton],
      afterOpenHook: () => {
        drawerButton.focus();
      },
      afterCloseHook: () => {
        drawerButton.focus();
      }
    });

    drawerMenu.addEventListener('click', (event) => {
      if (event.target === drawerMenu) {
        drawerDropdown.close();
      }
    });

    /**
     * Setup in drawer menus/dropdowns
     */
    taps.forEach(tap => tap.addEventListener('click', preventDefault), false);
    taps.forEach(tap => tap.addEventListener('keydown', onTapEnterKeydown, false));

    const inDrawerDropdowns = inDrawerMenus.map(menu => new Dropdown(menu, {
      hover: true,
      beforeOpenHook: (dropdown) => {
        if (isBelowBreakpoint()) return;
        if (document.activeElement !== searchDesktop) return;
        if (dropdown.element.contains(searchDesktop)) return;
        searchDesktop.blur();
      }
    }));

    /**
     * all dropdowns list
     */
    const dropdowns = [
      drawerDropdown,
      localesMobileDropdown,
      localesDesktopDropdown,
      searchMobileDropdown
    ].concat(inDrawerDropdowns);


    /**
     * @param {Event} event
     */
    function handleFocus(event) {
      if (isBelowBreakpoint()) {
        return;
      }

      /* eslint no-invalid-this: 0 */
      const link = this;

      dropdowns
        .filter(dropdown => !dropdown.closed)
        .filter(dropdown => !dropdown.buttons.includes(link))
        .forEach(dropdown => dropdown.close());
    }

    // close opened dropdowns when other dropdown is opened by focus or hover
    focusLinks.forEach(link => link.addEventListener('focus', handleFocus, false));
    focusLinks.forEach(link => link.addEventListener('mouseenter', handleFocus, false));

    /**
     *
     */
    function handleResize() {
      if (isAboveBreakpoint()) {
        dropdowns
          .filter(dropdown => !dropdown.closed)
          .forEach(dropdown => dropdown.close());

        inDrawerDropdowns.forEach((dropdown) => {
          dropdown.turnOn('hover');
          dropdown.turnOn('body-click');
          dropdown.turnOff('click');
        });
      }

      if (isBelowBreakpoint()) {
        inDrawerDropdowns.forEach((dropdown) => {
          dropdown.turnOn('click');
          dropdown.turnOff('hover');
          dropdown.turnOff('body-click');
        });
      }
    }

    handleResize();
    window.addEventListener('resize', handleResize, false);
    window.addEventListener('orientationchange', handleResize, false);

    // setup skip to content link
    setupSkipToContent();

    // setup language menus
    populateNavLocaleLinks();
  });
}
